.form {
    background: var(--color-brownLight);
    padding: var(--space-3);
    margin: var(--space-6) 0 0;
    @media (--sm) {
        padding: var(--space-6);
        margin: var(--space-6) 0;
    }
}

.formHeaderDescription {
    max-width: 100%;
    margin-bottom: var(--space-3);
    @media (--md) {
        max-width: 50%;
    }
}

.formBottomDescription {
    max-width: 100%;
    margin-bottom: var(--space-3);
    @media (--md) {
        max-width: 50%;
        margin-bottom: var(--space-6);
    }
}
