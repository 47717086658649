/* Reset Button Styles */
.resetButton {
    align-items: center;
    appearance: none;
    border: none;
    box-sizing: border-box;
    display: inline-flex;
    line-height: 1;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    text-rendering: inherit;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    border-radius: 0;
    background-color: transparent;
    color: inherit;
}
/* Base Button Styles */
.button {
    @apply .resetButton; /* Assuming PostCSS plugin supports @apply */
    /* Dynamic values like `theme.fonts.regular` need to be defined as CSS variables */
    padding: 7px 16px;
    gap: 0.75em;
    transition: background-color 200ms ease-in;
    text-transform: uppercase;
    will-change: background-color;
    /* Directly use CSS variables for colors, fonts, etc. */
    color: var(--color-black, var(--color-white)); /* Fallback to white if --color is not defined */
    min-height: 48px;
    justify-content: center;
    cursor: pointer;
    /* SVG styles */
    svg {
        height: 25px;
        width: 25px;
        fill: currentColor;
    }
    svg path {
        fill: currentColor;
    }
    border-radius: var(--size-corner-radius-large);

    &:disabled {
        background-color: color-mix(in srgb, var(--color-black) 80%, transparent);
        cursor: not-allowed;
        &:hover {
            cursor: not-allowed;
            background-color: color-mix(in srgb, var(--color-black) 80%, transparent);
        }
    }
}

/* SIZE (small), default is LARGE */
.small {
    min-height: 32px;
    border-radius: var(--size-corner-radius);
    svg {
        height: 16px;
        width: 16px;
    }
}

.min-width {
    text-align: center;
    min-width: 100%;
    @media (--sm) {
        min-width: 380px;
    }
}

/* VARIANTS (primary | secondary | brand | campaign | plain | link | icon) */
.primary {
    background-color: var(--color-black);
    color: var(--color-white);

    &:hover {
        color: var(--color-white);

        background-color: color-mod(var(--color-black) l(40%));
        /* background-color: lighten(var(--color-black), 0.2); */
    }

    &:active,
    &:focus {
        background-color: color-mod(var(--color-black) l(30%));
    }
}

/* Variant and state styles will need to be applied based on additional class names or attributes in your HTML/JSX */
