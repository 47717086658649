.contentWrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.headlineText {
    text-align: center;
    margin-top: var(--space-6);
    margin-bottom: var(--space-4);
    flex-grow: 1;
    text-transform: none;
}

.contentText {
    padding: 0 var(--space-3);
    @media (--sm) {
        padding: 0;
    }
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loader {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #000;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}
@keyframes l3 {
    to {
        transform: rotate(1turn);
    }
}
