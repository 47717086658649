@use '~/theme/mixins/index.scss' as *;

.logoContainer {
    svg {
        height: 16px;
        width: 101px;

        @include breakpoint('sm') {
            height: 24px;
            width: 152px;
        }
    }
}
