@use '~/theme/mixins/_breakpoint.scss' as *;

$font-families: (
    regular: (
        DINPro,
        Arial,
        Sans-Serif,
    ),
    alternative: (
        Echo Soul Alt Regular,
        Arial,
        Sans-Serif,
    ),
);

@mixin serialize-font-weight($font-weight) {
    $mapped-weight: (
        'Thin': 100,
        'Hairline': 100,
        'ExtraLight': 200,
        'UltraLight': 200,
        'Light': 300,
        'Normal': 400,
        'Regular': 400,
        'Medium': 500,
        'DemiBold': 600,
        'SemiBold': 600,
        'Bold': 700,
        'ExtraBold': 800,
        'UltraBold': 800,
        'Black': 900,
        'Heavy': 900,
        'ExtraBlack': 950,
        'UltraBlack': 950,
    );

    font-weight: if(
        map-has-key($mapped-weight, $font-weight),
        map-get($mapped-weight, $font-weight),
        $font-weight
    );
}

@mixin text-style(
    $font-family-key,
    $font-weight,
    $font-size,
    $line-height,
    $letter-spacing: normal,
    $text-transform: none
) {
    font-family: map-get($font-families, $font-family-key);
    @include serialize-font-weight($font-weight);
    font-size: $font-size;
    line-height: $line-height;
    letter-spacing: $letter-spacing;
    text-transform: $text-transform;
}

@mixin productName {
    @include text-style('regular', Medium, 18px, 26px, 0px, inherit);

    @include breakpoint('md', 'down') {
        line-height: 20px;
    }

    @include breakpoint('sm', 'down') {
        font-size: 12px;
        line-height: 18px;
    }
}

@mixin productInfo {
    @include text-style('regular', 'Regular', 14px, 20px, 'normal', inherit);

    @include breakpoint('md', 'down') {
        font-size: 12px;
        line-height: 16px;
    }

    @include breakpoint('sm', 'down') {
        font-size: 10px;
        line-height: 11px;
    }
}
