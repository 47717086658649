@use '~/theme/variables/index.scss' as theme;
@use '~/theme/mixins/index.scss' as *;

.productCardComponent {
    height: 100%;
    display: flex;
    flex-direction: column;

    img {
        mix-blend-mode: multiply;
    }

    .productCardName {
        @include productName;
    }

    .productDetails {
        background-color: theme.$color-white;
        display: flex;
        width: 100%;
        box-sizing: border-box;
        justify-content: flex-end;
        flex-direction: column;
        text-align: center;
        color: theme.$color-black;
        overflow: auto;
        padding: theme.$space-3 theme.$space-4 theme.$space-4;

        @include breakpoint('sm', 'down') {
            padding: theme.$space-3;
        }
    }

    .productLabel {
        @include productInfo;
        font-weight: 500;
        margin-top: theme.$space-2;
    }

    .productDescription {
        @include productInfo;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .productFooter {
        position: relative;
        margin-top: theme.$space-2;
        padding: theme.$space-4 0 0;
    }

    .productAspectRation {
        background-color: theme.$color-brownUltraLight;
    }
}
