.footer {
    background-color: var(--color-black);
    align-self: end;
    padding-bottom: 0;
    width: 100%;

    &:empty {
        display: none;
    }
}

.footerContainer {
    display: flex;
    flex-direction: column;
    padding: var(--space-6) 0 var(--space-2);
    margin: 0 auto;
    width: '100%';

    @media (--sm) {
        padding-top: 32px; /* Assuming theme.space[5] = 32px */
    }
}
