@use '~/theme/variables/index.scss' as theme;

.imageWrapper {
    position: relative;
    width: inherit;
    height: inherit;
    display: inherit;
    aspect-ratio: inherit;

    img {
        opacity: 1;
        transition: opacity 0.15s theme.$easing-smoothEase;
    }
}

.loading {
    background-color: theme.$color-brownUltraLight;
    overflow: hidden;

    img {
        opacity: 0;
    }
}
