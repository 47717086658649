@use '~/theme/variables/index.scss' as theme;
@use '~/theme/mixins/index.scss' as *;

.gutter {
    padding-left: theme.$space-3;
    padding-right: theme.$space-3;
    flex: initial;

    @include breakpoint('md') {
        padding-left: theme.$space-5;
        padding-right: theme.$space-5;
    }

    &.no-gutter {
        padding-left: 0;
        padding-right: 0;

        @include breakpoint('md') {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.extraSmall:not(.no-gutter) {
        padding: 0 theme.$space-1;
    }

    &.small:not(.no-gutter) {
        padding-left: theme.$space-2;
        padding-right: theme.$space-2;

        @include breakpoint('md') {
            padding-left: theme.$space-3;
            padding-right: theme.$space-3;
        }
    }

    &.medium:not(.no-gutter) {
        padding-left: theme.$space-3;
        padding-right: theme.$space-3;

        @include breakpoint('md') {
            padding-left: theme.$space-4;
            padding-right: theme.$space-4;
        }
    }

    &.large:not(.no-gutter) {
        padding-left: theme.$space-4;
        padding-right: theme.$space-4;

        @include breakpoint('md') {
            padding-left: theme.$space-5;
            padding-right: theme.$space-5;
        }
    }

    &.xl:not(.no-gutter) {
        padding-left: theme.$space-4;
        padding-right: theme.$space-4;

        @include breakpoint('md') {
            padding-left: theme.$space-5;
            padding-right: theme.$space-5;
        }
    }
}
