@use '~/theme/mixins/index.scss' as *;

.cardCarouselLoader {
    min-height: 616px;

    @include breakpoint('sm') {
        min-height: 620px;
    }
    @include breakpoint('lg') {
        min-height: 660px;
    }
}
