@use '~/theme/variables/index.scss' as theme;
@use '~/theme/mixins/index.scss' as *;

.skeletonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: theme.$space-3;

    @include breakpoint('sm') {
        flex-direction: row;
        align-items: flex-start;
    }
}
